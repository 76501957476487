<template>
	<div id="app" :class="{ pcWidth: isPc() }">
		<Header
			v-if="isPc() && !$route.fullPath.includes('login') && !$route.fullPath.includes('eventtheme') && !$route.fullPath.includes('iceskating') && !$route.fullPath.includes('enversion') && !$route.fullPath.includes('international') && !$route.fullPath.includes('internationalEn')" />
		<keep-alive>
			<router-view :key="$route.fullPath" />
		</keep-alive>

		<Footer
			v-if="isPc() && !$route.fullPath.includes('iceskating') && !$route.fullPath.includes('enversion') && !$route.fullPath.includes('international') && !$route.fullPath.includes('internationalEn')" />
	</div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';

export default {
	name: 'App',
	components: {
		Header,
		Footer,
	},
	data() {
		return {
			links: [],
			status: true,
			leftData: [],
			rightData: [],
			centerData: [],
			logoUrl: ''
		}
	},
	mounted() {
		// this.findSkipList();
		this.getAllList()
		this.getStaionInfo()
		this.getIsGray()

		const currentPath = window.location.pathname
		const queryParams = window.location.search

		if (currentPath == '/articleDetails' && !this.isPc() && !this.getQuery()) {
			window.location.href = '/h5/H5articledetails' + queryParams
			this.$store.commit("EXIT_TOKEN")
		} else if (!this.isPc() && !this.getQuery()) {
			window.location.href = '/h5'
			// window.location.href = this.$baseURL + '/h5'
			this.$store.commit("EXIT_TOKEN")
		}
	},
	methods: {

		async findSkipList() {
			// this.links = [];
			// let res = await this.$api.findSkipList({type:2});
			// this.links = res.data.data
		},
		async getIsGray() {
			let res = await this.$api.getIsGray()
			if (res.data.data) {
				$("html").css({
					"filter": "grayscale(100%)",
					"-webkit-filter": " grayscale(100%)",
					"-moz-filter": "grayscale(100%)",
					"-ms-filter": " grayscale(100%)",
					"-o-filter": "grayscale(100%)",
					"filter": "url('data:image/svg+xml;utf8,#grayscale')",
					"filter:progid": "DXImageTransform.Microsoft.BasicImage(grayscale=1)",
					"-webkit-filter": " grayscale(1)"
				})
			}
		},
		async getAllList() {
			// let res  = await this.$api.getAdvList();
			// console.log(res.data.data,'----------')
			// //fenzu
			// this.links = [];
			// res.data.data.forEach(item=>{
			//  if(item.type == 2){
			//     this.links.push(item)
			//   }else if(item.type == 3){
			//     this.leftData.push(item)
			//   }else if(item.type ==4){
			//     this.rightData.push(item)
			//   }else if(item.type == 5){
			//     this.centerData.push(item)
			//  }
			// })

		},
		async getStaionInfo() {
			// let res = await this.$api.getStationInfo()
			// //添加站点信息
			// document.querySelector('meta[name=keywords]').setAttribute('content', res.data.data.keyword);
			// document.querySelector('meta[name=description]').setAttribute('content', res.data.data.description);
			// this.logoUrl = res.data.data.logoUrl
			// document.title = res.data.data.title;
			// //存储站点信息
			// this.$store.commit('setStations',res.data.data)
		},
		toDetail(item) {
			if (item.skipUrl.includes('http')) {
				window.open(item.skipUrl);
			} else {
				this.$router.push(item.skipUrl)
			}
		},
		// 判断访问端是手机还是pc
		isPc() {
			var sUserAgent = navigator.userAgent.toLowerCase();
			var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
			var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
			var bIsMidp = sUserAgent.match(/midp/i) == "midp";
			var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
			var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
			var bIsAndroid = sUserAgent.match(/android/i) == "android";
			var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
			var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
			if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
				return false
			} else {
				return true
			}
		},
		// 获取路由中的值判断是否含有h5
		getQuery() {
			var href = window.location.href;
			return href.includes('/h5')
		},
	}
}
</script>

<style scoped>
#app {
	position: relative;
}

.pcWidth {
	min-width: 1200PX;
}

.rightside {
	font-weight: bold;
	width: auto;
}

.rightside .nav-item {
	/* width: 53PX;
  height: 72PX; */
	width: 65PX;
	height: 76PX;
	border-bottom: 1PX solid #d9e6ff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.nav-item:last-child {
	border: none;
}

.nav-item .img {
	/* width: 33PX; */
	width: 40PX;
	height: auto;
}

.nav-item .wz {
	color: #4465aa;
	margin-top: 6PX;
}

@media screen and (max-width: 1200PX) {
	.hidden-lg {
		display: none;
	}
}
</style>