<template>
	<div>
		<div class="searchfor">
			<div class="input">
				<el-input class="input-with-select" placeholder="裁判员注册" readonly style="font-size: 15PX;"
					placeholder-class="custom-placeholder">
					<el-button slot="append" style="font-size: 17PX;" @click="applyfor">去注册</el-button>
				</el-input>
			</div>
		</div>

		<div class="nav">
			<div @click="getnav(1)" :class="navIndex == 1 ? 'navselect' : ''">裁判员证书全览</div>
			<div @click="getnav(2)" :class="navIndex == 2 ? 'navselect' : ''">裁判员全览</div>
		</div>
		<div class="xian"></div>

		<div v-if="navIndex == 1">
			<div class="nian" style="margin-top: 20px;">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/name.png" alt="">
					<p>姓名</p>
				</div>
				<el-input placeholder="输入姓名" v-model="info.refereeName" style="width: 194PX;"></el-input>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/project.png" alt="">
					<p>项目</p>
				</div>
				<div class="select">
					<!-- <el-select v-model="info.sports" placeholder="请选择" filterable  collapse-tags multiple>
						<el-option v-for="item in options1" :key="item.itemValue" :label="item.itemText"
							:value="item.itemValue">
						</el-option>
					</el-select> -->
					<a-select option-label-prop="label" optionFilterProp="label" placeholder="请选择" v-model="info.sports"
						mode="multiple" allowClear showSearch :maxTagCount="1">
						<a-select-option v-for="item in options1" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.sports" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/level.png" alt="">
					<p>级别</p>
				</div>
				<div class="select">
					<!-- <el-select v-model="info.refereeLevel" placeholder="请选择"  collapse-tags multiple>
						<el-option v-for="item in options2" :key="item.itemValue" :label="item.itemText"
							:value="item.itemValue">
						</el-option>
					</el-select> -->
					<a-select option-label-prop="label" placeholder="请选择" v-model="info.refereeLevel" mode="multiple"
						allowClear :maxTagCount="1" optionFilterProp="label">
						<a-select-option v-for="item in options2" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.refereeLevel" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/area.png" alt="">
					<p>所在地</p>
				</div>
				<div class="select">
					<!-- <el-select v-model="info.certificateArea" placeholder="请选择"  collapse-tags multiple>
						<el-option v-for="item in options3" :key="item.itemValue" :label="item.itemText"
							:value="item.itemValue">
						</el-option>
					</el-select> -->
					<a-select option-label-prop="label" placeholder="请选择" v-model="info.certificateArea" mode="multiple"
						allowClear :maxTagCount="1" optionFilterProp="label">
						<a-select-option v-for="item in options3" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.certificateArea" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/statuss.png" alt="">
					<p>状态</p>
				</div>
				<div class="select selects">
					<!-- <el-select v-model="info.applyForStatus" placeholder="请选择"  collapse-tags multiple>
						<el-option v-for="item in options4" :key="item.itemValue" :label="item.itemText"
							:value="item.itemValue">
						</el-option>
					</el-select> -->
					<!--  -->
					<a-select option-label-prop="label" placeholder="请选择" v-model="info.applyForStatus" allowClear
						optionFilterProp="label" mode="multiple"  :maxTagCount="1">
						<a-select-option v-for="item in options4" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.applyForStatus" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/year.png" alt="">
					<p>审批年份</p>
				</div>
				<div class="select">
					<el-date-picker placeholder="请选择开始时间" class="year" v-model="info.startDate" type="month"
						format="yyyy-MM" value-format="yyyy-MM">
						<img slot="prefix" src="../../assets/image/year.png" alt="" srcset="">
					</el-date-picker>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/year.png" alt="">
					<p>审批年份</p>
				</div>
				<div class="select">
					<el-date-picker placeholder="请选择结束时间" class="year" v-model="info.endDate" type="month"
						format="yyyy-MM" value-format="yyyy-MM">
						<img slot="prefix" src="../../assets/image/year.png" alt="" srcset="">
					</el-date-picker>
				</div>
			</div>
			<div class="xian2"></div>

			<div style="display: flex; margin: 0 28px">
				<div class="but" @click="searchfor">
					搜索
				</div>

				<div class="but" @click="reset" style="margin-left: 10px;">
					重置
				</div>
			</div>

			<div class="piece">
				<div class="backck" v-for="(item, index) in data" :key="index" @click="getdetails(item)"
					v-if="data && data.length > 0">
					<div class="information">
						<div class="headsculpture">
							<img :src="item.image">
						</div>
						<div class="specific-box">
							<div class="specific">
								<div class="identifying"></div>
								<div class="definition">{{ item.refereeName }}</div>
								<img class="garden" src="../../assets/image/garden.png" alt="">
							</div>
							<div class="box">
								<div class="step">{{ item.sports_dictText }}</div>
								<div class="step">{{ item.refereeLevel }}</div>
								<div class="step" v-if="item.applyForStatus == 40">已注册</div>
								<div class="step" v-if="item.applyForStatus == 60">未年度注册</div>
								<!-- <div class="step" v-if="item.applyForStatus == '--'">--</div> -->
								<div class="step" v-if="item.applyForStatus == '无需注册'">无需注册</div>
								<div class="step">注册所在区：{{ item.certificateArea }}</div>
							</div>
						</div>
					</div>
					<img src="../../assets/image/coach.png" alt="">
				</div>
			</div>
		</div>

		<div v-else>
			<div class="nian" style="margin-top: 20px;">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/name.png" alt="">
					<p>姓名</p>
				</div>
				<el-input placeholder="输入姓名" v-model="info.refereeName" style="width: 194PX;"></el-input>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/level.png" alt="">
					<p>性别</p>
				</div>
				<div class="select">
					<!-- <el-select v-model="info.refereeSex" placeholder="请选择"  collapse-tags multiple>
						<el-option v-for="item in options6" :key="item.itemValue" :label="item.itemText"
							:value="item.itemValue">
						</el-option>
					</el-select> -->
					<a-select option-label-prop="label" placeholder="请选择" v-model="info.refereeSex" mode="multiple"
						allowClear :maxTagCount="1" optionFilterProp="label">
						<a-select-option v-for="item in options6" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.refereeSex" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/project.png" alt="">
					<p>项目</p>
				</div>
				<div class="select">
					<!-- <el-select v-model="info.sports" placeholder="请选择" filterable  collapse-tags multiple>
						<el-option v-for="item in options1" :key="item.itemValue" :label="item.itemText"
							:value="item.itemValue">
						</el-option>
					</el-select> -->

					<a-select option-label-prop="label" placeholder="请选择" v-model="info.sports" mode="multiple"
						allowClear showSearch :maxTagCount="1" optionFilterProp="label">
						<a-select-option v-for="item in options1" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.sports" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/level.png" alt="">
					<p>级别</p>
				</div>
				<div class="select">
					<!-- <el-select v-model="info.refereeLevel" placeholder="请选择"  collapse-tags multiple>
						<el-option v-for="item in options2" :key="item.itemValue" :label="item.itemText"
							:value="item.itemValue">
						</el-option>
					</el-select> -->
					<a-select option-label-prop="label" placeholder="请选择" v-model="info.refereeLevel" mode="multiple"
						allowClear :maxTagCount="1" optionFilterProp="label">
						<a-select-option v-for="item in options2" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.refereeLevel" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../assets/image/area.png" alt="">
					<p>所在地</p>
				</div>
				<div class="select">
					<!-- <el-select v-model="info.certificateArea" placeholder="请选择"  collapse-tags multiple>
						<el-option v-for="item in options3" :key="item.itemValue" :label="item.itemText"
							:value="item.itemValue">
						</el-option>
					</el-select> -->
					<a-select option-label-prop="label" placeholder="请选择" v-model="info.certificateArea" mode="multiple"
						allowClear :maxTagCount="1" optionFilterProp="label">
						<a-select-option v-for="item in options3" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.certificateArea" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>

			<div style="display: flex; margin: 0 28px">
				<div class="but" @click="searchfor">
					搜索
				</div>

				<div class="but" @click="reset" style="margin-left: 10px;">
					重置
				</div>
			</div>

			<div class="piece">
				<div class="backck" v-for="(item, index) in data" :key="index" @click="getdetails(item)"
					v-if="data && data.length > 0">
					<div class="information">
						<div class="headsculpture">
							<img :src="item.image">
						</div>
						<div class="specific-box">
							<div class="specific">
								<div class="identifying"></div>
								<div class="definition">{{ item.refereeName }}</div>
								<img class="garden" src="../../assets/image/garden.png" alt="">
							</div>
							<!-- <div class="box">
								<div class="step">{{ item.sportName }}</div>
								<div class="step">{{ item.referee_level }}</div>
								<div class="step">注册所在区：{{ item.certificate_area }}</div>
							</div> -->
						</div>
					</div>
					<img src="../../assets/image/coach.png" alt="">
				</div>
			</div>
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :type="2" :go="12" :pageSize="12"
				:refreshNum="refreshNum" :pageNo="info.pageNo"></pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		id: {
			type: [Number, String],
			default: ''
		},
		pid: {
			type: [Number, String],
			default: ''
		},
	},
	components: {},
	data() {
		return {
			options1: [],
			options2: [],
			options3: [],
			options4: [
				{
					itemText: '已注册',
					itemValue: '40'
				},
				{
					itemText: '未年度注册',
					itemValue: '60'
				},
				{
					itemText: '无需注册',
					itemValue: '无需注册'
				}
			],
			options6: [
				{
					itemText: '男',
					itemValue: 1
				},
				{
					itemText: '女',
					itemValue: 2
				}
			],
			info: {
				userId: null,
				pageNo: 1,
				pageSize: 12,
				refereeLevel: undefined,
				certificateArea: undefined,
				sports: undefined,
				refereeName: undefined,
				refereeSex: undefined,
				applyForStatus: undefined,
				startDate: '',
				endDate: '',
			},
			total: 0,
			data: [],
			navIndex: 1,
			refreshNum: 1
		}
	},
	created() {
		this.sportEventList()
		this.judgmentLevel()
		this.areaList()
		this.getRefereeBase()

		this.$getwxsdk('裁判列表 - 北京市体育竞赛管理和国际交流中心')
	},
	methods: {
		currentchange(val) {
			this.info.pageNo = val
			if (this.navIndex == 2) {
				this.selectAllRefereeUser()
			} else {
				this.getRefereeBase()
			}
		},
		searchfor() {
			this.info.pageNo = 1
			if (this.navIndex == 2) {
				this.selectAllRefereeUser()
			} else {
				this.getRefereeBase()
			}
		},
		reset() {
			this.info = {
				pageNo: 1,
				pageSize: 12,
				refereeLevel: undefined,
				certificateArea: undefined,
				sports: undefined,
				refereeName: undefined,
				refereeSex: undefined,
				applyForStatus: undefined,
				startDate: '',
				endDate: '',
			}
			this.data = []
			this.total = 0
			if (this.navIndex == 2) {
				this.selectAllRefereeUser()
			} else {
				this.getRefereeBase()
			}
		},
		getRefereeBase() {
			const infoCopy = JSON.parse(JSON.stringify(this.info));
			if (!this.$verification(infoCopy.sports)) {
				infoCopy.sports = infoCopy.sports.join(",")
			} else {
				infoCopy.sports = ''
			}
			if (!this.$verification(infoCopy.applysportsForStatus)) {
				infoCopy.applysportsForStatus = infoCopy.applysportsForStatus.join(",")
			} else {
				infoCopy.applysportsForStatus = ''
			}
			if (!this.$verification(infoCopy.refereeLevel)) {
				infoCopy.refereeLevel = infoCopy.refereeLevel.join(",")
			} else {
				infoCopy.refereeLevel = ''
			}
			if (!this.$verification(infoCopy.certificateArea)) {
				infoCopy.certificateArea = infoCopy.certificateArea.join(",")
			} else {
				infoCopy.certificateArea = ''
			}
			if(!this.$verification(infoCopy.applyForStatus)){
				infoCopy.applyForStatus = infoCopy.applyForStatus.join(",")
			}else{
				infoCopy.applyForStatus = ''
			}
			if (!this.$verification(infoCopy.refereeSex)) {
				infoCopy.refereeSex = infoCopy.refereeSex.join(",")
			} else {
				infoCopy.refereeSex = ''
			}
			this.$api.getRefereeBase(infoCopy).then(res => {
				res.data.data.result.records.forEach(item => {
					for (let i in this.options3) {
						if (item.certificateArea == this.options3[i].itemValue) {
							item.certificateArea = this.options3[i].itemText
						}
					}
					for (let i in this.options2) {
						if (item.refereeLevel == this.options2[i].itemValue) {
							item.refereeLevel = this.options2[i].itemText
						}
					}
					if (item.avatar) {
						this.$api.getFile({ objectName: item.avatar }).then(ess => {
							this.$convert(ess.data.data.result.url).then((fileUrl) => {
								item.image = fileUrl
								this.$forceUpdate()
							})
						})
					} else {
						item.image = require('@/assets/image/defaults.png')
						this.$forceUpdate()
					}
				})
				res.data.data.result.records.forEach(item => {
					item.refereeName = this.$decrypt(item.refereeName || '')
				})
				if (this.navIndex == 1) {
					this.data = res.data.data.result.records
					this.total = res.data.data.result.total
				}
			})
		},
		sportEventList() {
			this.$api.sportEventList({ dictValue: '其他' }).then(res => {
				this.options1 = res.data.data.result
			})
		},
		judgmentLevel() {
			this.$api.judgmentLevel().then(res => {
				this.options2 = res.data.data.result
			})
		},
		areaList() {
			this.$api.areaList().then(res => {
				this.options3 = res.data.data.result
			})
		},
		getdetails(row) {
			if (this.navIndex == 1) {
				this.$router.push({
					path: '/h5/h5refereedetails',
					query: {
						sports: row.sports,
						id: row.refereeId,
						refresh: new Date().getTime(),
						columnId: this.$route.query.id,
						columnPid: this.$route.query.pid
					}
				});
			} else {
				this.$router.push({
					path: '/h5/h5refereedetailss',
					query: {
						sports: row.sports,
						id: row.userId,
						refresh: new Date().getTime(),
						columnId: this.$route.query.id,
						columnPid: this.$route.query.pid
					}
				});
			}
		},
		applyfor() {
			if (this.$store.state.token == '') {
				this.$confirm('当前尚未登录，请登录', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.commit("EXIT_TOKEN")
					this.$router.push({
						path: '/h5/login',
						query: {
							person: true,
							grp: false,
							refresh: new Date().getTime()
						}
					})
				}).catch(() => { });
				return
			}
			if (this.$store.state.userType == 'user_type_02') {
				this.$confirm('当前账号为团体账号，是否切换成个人账号', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.commit("EXIT_TOKEN")
					this.$router.push({
						path: '/h5/login',
						query: {
							person: true,
							grp: false,
							refresh: new Date().getTime()
						}
					})
				}).catch(() => { });
				return
			}
			if (this.$store.state.realnameornot.caStatus == 0) { // 未人脸识别
				if (!this.$store.state.realnameornot.createTime) {
					this.$confirm('当前账号尚未身份验证，是否前往身份验证', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$router.push({
							path: '/h5/registerIndex',
							query: {
								refresh: new Date().getTime(),
								menuIndex: 4,
								nameornot: false,
							},
						})
					}).catch(() => { });

				}
				if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus != 1) {

					this.$alert('当前账号身份验证审核中，请等待审核完成', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/h5/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 4,
									nameornot: true,
								},
							})
						}
					});


				}
				if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 1) {
					this.$router.push({
						path: '/h5/registerIndex',
						query: {
							refresh: new Date().getTime(),
							newlyindex: 4,
							newly: false,
							menuIndex: 1
						},
					})
				}
			} else { // 通过人脸识别
				if (this.$store.state.realnameornot.caStatus == 2) {
					this.$alert('当前账号人脸识别未通过，请重新认证', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/h5/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 4,
									nameornot: true
								},
							})
						}
					});
				} else if (this.$store.state.realnameornot.requeststatus == 0 && this.$verification(this.$store.state.realnameornot.headPic)) {
					this.$alert('当前账号未上传免冠照，请上传', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/h5/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 7,
									photoau: false
								},
							})
						}
					});
				} else if (this.$store.state.realnameornot.requeststatus == 0 && !this.$verification(this.$store.state.realnameornot.headPic)) {
					this.$alert('当前账号免冠照审核中，请等待审核完成', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/h5/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 7,
									photoau: true
								},
							})
						}
					});
				} else if (this.$store.state.realnameornot.requeststatus == 2) {
					this.$alert('当前账号免冠照未通过，请从新上传', '提示', {
						confirmButtonText: '确定',
						type: 'warning',
						callback: action => {
							this.$router.push({
								path: '/h5/registerIndex',
								query: {
									refresh: new Date().getTime(),
									menuIndex: 7,
									photoau: true
								},
							})
						}
					});
				} else if (this.$store.state.realnameornot.caStatus == 1 && this.$store.state.realnameornot.requeststatus == 1) {
					this.$router.push({
						path: '/h5/registerIndex',
						query: {
							refresh: new Date().getTime(),
							newlyindex: 4,
							newly: false,
							menuIndex: 1
						},
					})
				}
			}

		},
		getnav(e) {
			this.navIndex = e
			this.reset()
			this.refreshNum = e
		},
		selectAllRefereeUser() {
			const infoCopy = JSON.parse(JSON.stringify(this.info));
			if (!this.$verification(infoCopy.sports)) {
				infoCopy.sports = infoCopy.sports.join(",")
			} else {
				infoCopy.sports = ''
			}
			if (!this.$verification(infoCopy.applysportsForStatus)) {
				infoCopy.applysportsForStatus = infoCopy.applysportsForStatus.join(",")
			} else {
				infoCopy.applysportsForStatus = ''
			}
			if (!this.$verification(infoCopy.refereeLevel)) {
				infoCopy.refereeLevel = infoCopy.refereeLevel.join(",")
			} else {
				infoCopy.refereeLevel = ''
			}
			if (!this.$verification(infoCopy.certificateArea)) {
				infoCopy.certificateArea = infoCopy.certificateArea.join(",")
			} else {
				infoCopy.certificateArea = ''
			}
			// if(!this.$verification(infoCopy.applyForStatus)){
			// 	infoCopy.applyForStatus = infoCopy.applyForStatus.join(",")
			// }else{
			// 	infoCopy.applyForStatus = ''
			// }
			if (!this.$verification(infoCopy.refereeSex)) {
				infoCopy.refereeSex = infoCopy.refereeSex.join(",")
			} else {
				infoCopy.refereeSex = ''
			}
			this.$api.selectAllRefereeUser(infoCopy).then(res => {
				res.data.data.result.records.forEach(item => {
					if (item.avatar) {
						this.$api.getFile({ objectName: item.avatar }).then(ess => {
							this.$convert(ess.data.data.result.url).then((fileUrl) => {
								item.image = fileUrl
								this.$forceUpdate()
							})
						})
					} else {
						item.image = require('@/assets/image/defaults.png')
						this.$forceUpdate()
					}
				})
				res.data.data.result.records.forEach(item => {
					if (!this.$verification(item.refereeName)) {
						item.refereeName = this.$decrypt(item.refereeName || '')
					}
				})
				if (this.navIndex == 2) {
					this.data = res.data.data.result.records
					this.total = res.data.data.result.total
				}
			})
		},
	}
}
</script>

<style scoped lang="scss">
.input {
	margin: 22PX;
}

.el-input-group {
	background: #F2F5FC;
	border-radius: 20PX;
	border: 1PX solid #1E71EF;
}

.input ::v-deep .el-input__inner {
	height: 34PX;
	height: 39PX;
	background: #F2F5FC;
	border: none;
	border-top-left-radius: 20PX;
	border-bottom-left-radius: 20PX;
	text-align: center;
}

.input ::v-deep .el-input-group__append {
	border: none;
	color: #fff;
	width: 10PX;
	height: 39PX;
	border-radius: 20PX;
	background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
}

.input ::v-deep .el-input-group__append button.el-button {
	font-weight: 400;
	font-size: 15PX !important;
}

.input ::v-deep input::-webkit-input-placeholder {
	color: #1E71EF;
}

.input ::v-deep input::-moz-placeholder {
	color: #1E71EF;
}

.nian {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 28PX;

	img {
		width: 17PX;
		height: 17PX;
		flex-shrink: 0;
	}

	p {
		font-size: 14PX;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin-left: 12PX;
		flex-shrink: 0;
	}

	.select ::v-deep .el-select .el-input .el-input__inner {
		border-color: transparent !important;
		background-color: transparent !important;
		box-shadow: none !important;
	}

	.select ::v-deep .el-select .el-input,
	.select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
		transform: rotateZ();
	}

	.select ::v-deep .el-select .el-input .el-select__caret {
		color: #C0C4CC;
		font-size: 14PX;
		transition: transform .3s;
		transform: rotateZ(89deg);
		cursor: pointer;
		left: 15PX;
		position: relative;
	}

	.select ::v-deep input::-webkit-input-placeholder {
		color: #000;
	}

	.select ::v-deep .el-select .el-input .el-select__caret {
		color: #000;
	}

	::v-deep .el-input .el-input__inner {
		border: none !important;
		padding: 0 12PX;
		color: #000;
	}

	::v-deep input::-webkit-input-placeholder {
		color: #000;
	}
}

.xian2 {
	margin: 0 28PX;
	height: 1PX;
	background: rgba(194, 194, 194, 0.2);
}

.but {
	margin: 20PX 0 20PX 0;
	width: calc(100% - 56PX);
	height: 37PX;
	background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
	border-radius: 50PX;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16PX;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #FFFFFF;
}

.paging {
	margin-left: 28PX;
	margin-bottom: 28PX;
	display: flex;
	align-items: center;
}

.paging span {
	margin-right: 5PX;
}

.piece {
	margin-top: 20PX;
}


.backck {
	height: 160PX;
	cursor: pointer;
	margin: 0 28PX 20PX;
}

.backck img {
	width: 100%;
	height: 100%;
}

.backck .information {
	display: flex;
	justify-content: space-between;
}

.backck .information .headsculpture {
	position: absolute;
	width: 90PX;
	height: 130PX;
	margin-top: 17PX;
	margin-left: 14PX;
}

.backck .information .headsculpture img {
	width: 100%;
	height: 100%;
	border-radius: 10PX;
}

.specific-box {
	position: absolute;
	margin-left: 135PX;
	width: calc(100% - 191PX);
}

.specific-box .specific {
	display: flex;
}



.specific-box .specific .identifying {
	width: 4PX;
	height: 21PX;
	background: #0046A3;
	margin-top: 31PX;
}

.specific-box .specific .definition {
	font-size: 20PX;
	font-weight: bold;
	color: #0046A3;
	margin-left: 12PX;
	margin-top: 28PX;
	width: 50%;
	white-space: nowrap; /* 确保文本在一行内显示 */
	overflow: hidden; /* 隐藏超出容器的文本 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
}

.specific-box .specific .garden {
	width: 24.6PX;
	height: 24.6PX;
	margin-top: 19PX;
	margin-left: -10PX;
}

.step {
	font-size: 14PX;
	font-weight: 400;
	color: #000;
	width: 150PX;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	margin-top: 2PX;
}

.box {
	margin-top: 12PX;
}

.boxspan {
	width: 45PX;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
}

.el-select-dropdown__item {
	font-size: 18PX;
}

.nav {
	display: flex;
	justify-content: center;
	margin-top: 27PX;

	div {
		width: 42%;
		height: 35PX;
		background: #E8F2FF;
		border-radius: 20PX 20PX 0PX 0PX;
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 15PX;
		color: #437DCA;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16PX;
		cursor: pointer;
	}
}

.xian {
	width: 100%;
	height: 2PX;
	background: #85B0E9;
	border-radius: 1PX;
	margin-bottom: 10PX;
}

.navselect {
	background: linear-gradient(90deg, #6FB2FF, #095FFF) !important;
	box-shadow: 0PX 7PX 16PX 0PX rgba(50, 129, 255, 0.51);
	border-radius: 20PX 20PX 0PX 0PX;
	color: #ffffff !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
	width: 194PX;
}

::v-deep .el-input__prefix,
.el-input__suffix {
	display: none;
}

::v-deep .selects .ant-select-selection__rendered {
	margin-left: -2PX !important;
}
</style>
